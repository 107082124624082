<template>
	<div class="account-pages my-5 pt-5">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6 col-xl-5">
					<div class="card overflow-hidden">
						<div class="bg-primary">
							<div class="text-primary text-center p-4">
								<h5 class="text-white font-size-20" id="regTitle">Sign Up</h5>
								<p class="text-white-50">Register for a free account.</p>
								<a @click.prevent="$router.push({ name: 'home' })" class="logo logo-admin">
									<img src="@/assets/images/logo-sm.png" height="50" alt="logo" />
								</a>
							</div>
						</div>

						<div class="card-body p-4">
							<div class="p-3">
								<b-alert
									v-model="registerSuccess"
									class="mt-5 text-center"
									variant="success"
									dismissible
									id="regSuccess"
								>Success! Please check your inbox for a verification link.</b-alert>

								<b-alert
									v-model="isRegError"
									variant="danger"
									class="mt-4"
									dismissible
									id="regError"
								>{{regError}}</b-alert>

								<b-form v-if="!registerSuccess" @submit.prevent="register" class="form-horizontal mt-4" id="registerForm">
									<b-form-group id="firstName-group" label="First Name" label-for="firstName">
										<b-form-input
											id="firstName"
											v-model="firstName"
											type="text"
											placeholder="Enter your first name"
											:class="{ 'is-invalid': $v.firstName.$error }"
											@input="$v.firstName.$touch"
											@blur="$v.firstName.$touch"
										></b-form-input>
										<div class="invalid-feedback" v-if="!$v.firstName.required && $v.firstName.$dirty">Field is required</div>
									</b-form-group>

									<b-form-group id="lastName-group" label="Last Name" label-for="lastName">
										<b-form-input
											id="lastName"
											v-model="lastName"
											type="text"
											placeholder="Enter your last name"
											:class="{ 'is-invalid': $v.lastName.$error }"
											@input="$v.lastName.$touch"
											@blur="$v.lastName.$touch"
										></b-form-input>
										<div class="invalid-feedback" v-if="!$v.firstName.required && $v.firstName.$dirty">Field is required</div>
									</b-form-group>

									<b-form-group id="email-group" label="Email address" label-for="email">
										<b-form-input 
											id="email"
											v-model="email"
											type="email"
											placeholder="Enter your email address"
											:class="{ 'is-invalid': $v.email.$error }"
											@input="$v.email.$touch"
											@blur="$v.email.$touch"
										></b-form-input>
										<div class="invalid-feedback" v-if="!$v.email.required && $v.email.$dirty">Field is required</div>
										<div class="invalid-feedback" v-else-if="!$v.email.email && $v.email.$dirty"> This is not a valid email </div>
									</b-form-group>

									<b-form-group id="password-group" label="Password" label-for="password">
										<b-form-input
											id="password"
											v-model="password"
											type="password"
											placeholder="Enter a password"
											:class="{ 'is-invalid': $v.password.$error }"
											@input="$v.password.$touch"
											@blur="$v.password.$touch"
										></b-form-input>
										<div class="invalid-feedback" v-if="!$v.password.required && $v.password.$dirty">Field is required</div>
										<div class="invalid-feedback" v-if="!$v.password.minLength">Must be at least 8 characters</div>
										<div class="invalid-feedback" v-if="!$v.password.maxLength">Maximum 30 characters</div>
									</b-form-group>

									<b-form-group id="passwordConfirm-group" label="Confirm Password" label-for="passwordConfirm">
										<b-form-input
											id="passwordConfirm"
											v-model="passwordConfirm"
											type="password"
											placeholder="Confirm password"
											:class="{ 'is-invalid': $v.passwordConfirm.$error }"
											@input="$v.passwordConfirm.$touch"
											@blur="$v.passwordConfirm.$touch"
										></b-form-input>
										<div class="invalid-feedback" v-if="!$v.passwordConfirm.required && $v.passwordConfirm.$dirty">Field is required</div>
										<div class="invalid-feedback" v-if="!$v.passwordConfirm.sameAsPassword">Password confirmation doesn't match</div>
										<div class="invalid-feedback" v-if="!$v.passwordConfirm.minLength">Must be at least 8 characters</div>
										<div class="invalid-feedback" v-if="!$v.passwordConfirm.maxLength">Maximum 30 characters</div>
									</b-form-group>

									<div class="form-group mb-0 text-center">
										<div class="col-12 text-center">
											<b-button type="submit" variant="primary" class="w-md" :disabled="isRegistering" id="signUpBtn">
												<span v-if="!isRegistering">Sign Up</span>
												<i v-else class="fas fa-spin fa-circle-notch"></i>
											</b-button>
										</div>
									</div>
									<div class="form-group mt-2 mb-0 row">
										<div class="col-12 mt-4 text-center">
											<p class="">
												By signing up you agree to the Turbo Time Tracker
												<router-link to="/terms">T&amp;C</router-link>.
											</p>
											<router-link tag="a" to="/login">
												Already registered? Click here to sign in.
											</router-link>
										</div>
									</div>
								</b-form>
							</div>
						</div>
						<!-- end card-body -->
					</div>
					<!-- end card -->
					<MiniFooter />
				</div>
				<!-- end col -->
			</div>
			<!-- end row -->
		</div>
	</div>
</template>

<script>
import appConfig from "@/app.config";
import { required, email, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'
import { apiPost } from '@/utils/api'
import MiniFooter from '@/components/account/mini-footer'; 

/**
 * Register component
 */
export default {
	name: 'SignUp',
	page: {
		title: "Register",
		meta: [{ name: "description", content: appConfig.description }]
	},
	components: { 
		MiniFooter,
	}, 	
	data() {
		return {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			passwordConfirm: '',
			isRegError: false,
			regError: null,
			isRegistering: false,
			registerSuccess: false,
		};
	},
	methods: {
		async register() {
			this.isRegError = false
			this.isRegistering = true
			this.registerSuccess = false

			this.$v.$touch()
			if (this.$v.$invalid) {
				this.isRegistering = false;
				this.regError = 'Please make sure all fields are filled in correctly.'
				this.isRegError = true
				return
			}

			const data = {
				first_name: this.firstName,
				last_name: this.lastName,
				email: this.email,
				password: this.password,
			}
			await apiPost('/register', {
				...data
			})
			.then(() => {
				this.isRegistering = false
				this.registerSuccess = true
			})
			.catch((error) => {
				// eslint-disable-next-line
				console.error('Error:', error)
				this.isRegistering = false
				if (error.response && error.response.data && error.response.data.errors) {
					let errorMessage = ''
					let errors = Object.values(error.response.data.errors)
					errors.forEach((arr) => errorMessage += arr.join(' '))
					this.regError = errorMessage
				} else {
					this.regError = 'An unexpected error occurred. If the problem persists, please contact support.'
				}
				this.isRegError = true
				this.isRegistering = false
			})
		}
	},
	validations: {
		firstName: { required },
		lastName: { required },
		email: { required, email },
		password: { 
			required,
			minLength: minLength(8),
			maxLength: maxLength(30)
		},
		passwordConfirm: {
			required,
			sameAsPassword: sameAs('password'),
			minLength: minLength(8),
			maxLength: maxLength(30)
		}
	},	
};
</script>