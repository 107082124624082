<template>
	<div class="mt-3 text-center">
		<p class="mb-0">
			© {{ new Date().getFullYear() }} Turbo Time Tracker. All Rights Reserved.
		</p>
		<p class="mb-0">
			<router-link to="/contact">
				Contact
			</router-link>
			|
			<router-link to="/privacy">
				Privacy
			</router-link>
			|
			<router-link to="/terms">
				T&amp;C
			</router-link>
		</p>
	</div>
</template>

<script>
export default {
	name: 'MiniFooter',
}
</script>